import { EventName, FeatureName, getMixPanelClient } from "@/app/mixpanel";
import { Button } from "@nextui-org/react";
import { useRouter } from "next/navigation";

export const GetCreditsButton = () => {
	const router = useRouter();
	const mp = getMixPanelClient();

	const handleClick = () => {
		mp.track(EventName.ViewCredits, {
			feature: FeatureName.Credits,
			location: "Credits Button",
			destination: "/pricing",
		});
		router.push("/pricing");
	};

	return (
		<Button
			color="warning"
			variant="flat"
			onClick={handleClick}
			className="font-medium"
		>
			Get Credits
		</Button>
	);
};
