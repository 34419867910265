"use client";

import React, { useState } from "react";
import {
	NavbarBrand,
	NavbarContent,
	Navbar as NextUINavbar,
	NavbarMenuToggle,
	NavbarMenu,
	NavbarMenuItem,
	Divider,
	NavbarItem,
} from "@nextui-org/react";
import { SkibsLogo } from "../SkibsLogo";
import { SignInButton } from "./SignUpButton";
import { Discord } from "./Discord";
import { BetaAccessChip } from "./BetaAccessChip";
import { EventName, getMixPanelClient } from "@/app/mixpanel";
import { BetaFeedback } from "./BetaFeedback";
import { LogoutButton } from "../Auth/LogoutButton";
import { FreeGenerationChip } from "./FreeGenerationsChip";
import { GetCreditsButton } from "../Auth/GetCreditsButton";
import Link from "next/link";
import { NavbarMyGenerations } from "./NavbarMyGenerations";

export const Navbar: React.FC = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const mp = getMixPanelClient();
	return (
		<NextUINavbar
			maxWidth="2xl"
			className="bg-primary"
			isBordered
			onMenuOpenChange={setIsMenuOpen}
		>
			<NavbarContent>
				<NavbarBrand className="gap-5">
					<a
						href="/"
						onClick={() => {
							mp.track(EventName.ClickOnLogo);
						}}
					>
						<SkibsLogo />
					</a>
					<div className="">
						<BetaAccessChip />
					</div>
				</NavbarBrand>
			</NavbarContent>
			<NavbarContent justify="end" className="sm:hidden">
				<SignInButton />
				<NavbarMenuToggle
					aria-label={isMenuOpen ? "Close menu" : "Open menu"}
				/>
			</NavbarContent>

			<NavbarContent className="hidden sm:flex gap-4" justify="end">
				<Discord />
				<NavbarMyGenerations />
				<GetCreditsButton /> {/* Add the Get Credits button here */}
				<SignInButton />
			</NavbarContent>

			<NavbarMenu>
				<NavbarMenuItem>
					<Link href={"/"}>Home</Link>
				</NavbarMenuItem>
				<NavbarMenuItem>
					<Link
						href={"/characters"}
						onClick={() => {
							mp.track(EventName.PageView, {
								page: "User Generations",
								current_url_path: "/characters",
							});
						}}
					>
						My characters
					</Link>
				</NavbarMenuItem>
				<NavbarMenuItem>
					<Link href={"/pricing"}>Get Credits</Link>{" "}
				</NavbarMenuItem>
				<NavbarMenuItem>
					<BetaFeedback />
				</NavbarMenuItem>
				<NavbarMenuItem>
					<Discord />
				</NavbarMenuItem>
				<Divider className="my-2" />
				<NavbarMenuItem>
					<LogoutButton />
				</NavbarMenuItem>
			</NavbarMenu>
		</NextUINavbar>
	);
};
